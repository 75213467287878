import router from '../router.js'
import store from '../store.js'
import md5 from "blueimp-md5"
import {
	l_setItem,
	l_getItem,
	getToken
} from '@/common/cache'
import {
	ElNotification
} from 'element-plus'
import axios from '../request/axios'

/**
 * 平台定时任务
 * 获取员工离职催办数据
*/
let noti
export function fetchQuitUrge() {
	if(!getToken())return
	axios({
		url: `/ent/v3/dimission/urge`,
		method: 'GET',
		hideLoading: true
	}).then(res => {
		if (res.code === 200) {
			if(res.data.count > 0) {
				noti && noti.close()
				noti = ElNotification({
					duration: 0,
					title: '员工离职审核催办通知',
					dangerouslyUseHTMLString: true,
					message: `
                        <div>您有<spen style="color:#F56C6C;">${res.data.count}位</spen>员工催办离职审核，请尽快办理<p style="text-decoration: underline;color:#1890FF;cursor: pointer;">现在去办理</p></div>
                    `,
					type: 'warning',
					onClick: function (){
						router.push('/dimission')
						this.close()
					}
				})

			}
		}
	})
}

/**
 * 平台定时任务
 * 获取企业审核状态
*/
export function fetchCompanyStatus() {
	const userInfo = l_getItem('USERINFO') || {}
	// 企业为审核状态，定时获取审核进度
	if (userInfo.company_status === 'apply') {
		axios({
			url: "/ent/v3/usercenter/info",
			method: "GET",
			hideLoading: true
		}).then(res => {
			if (res.code === 200) {
				let { user, member } = res.data

				const noticeKey = md5(`show_notice_${user.mobile}`)
				// 审核通过 && 未弹出过通知
				if (user.company_status === 'normal' && !l_getItem(noticeKey)) {

					l_setItem("USERINFO", user)
					store.dispatch('SETUSERINFO', user)
					l_setItem(noticeKey, true)
					store.dispatch('SETCOMPANY', member)

					ElNotification({
						duration: 0,
						dangerouslyUseHTMLString: true,
						title: '企业信息审核通知',
						message: '您提交的企业信息已审核通过！<p style="text-decoration: underline;color:#1890FF;cursor: pointer;">查看企业信息</p>',
						type: 'success',
						onClick: function (){
							router.push('/account')
							this.close()
						}
					})
				}
			}
		})
	}
}