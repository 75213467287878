<template>
    <div class="">
        <el-dialog v-model="dialogVisible" :lock-scroll="true" custom-class="companyInfo" width="560px" destroy-on-close append-to-body>
            <div class="header">
                <div class="flex_end_cen cont">
                    <el-image class="company_logo" style="width: 100px; height: 100px" fit="contain" :src="data.logo" lazy>
                        <template v-slot:error class="image-slot">
                            <img :src="$store.state.baseAvatar" />
                        </template>
                        <template v-slot:load class="image-slot">
                            <img :src="$store.state.baseAvatar" />
                        </template>
                    </el-image>
                </div>
                <div class="color_000 bold center fs_18 mt_15 mb_15">
                    {{ data.company_name }}
                </div>
                <div class="but_body">
                    <div class="button color_333 fs_14 flex_1" v-if="data.bussiness_industry">
                        {{ data.bussiness_industry }}
                    </div>
                    <div class="button color_333 fs_14 flex_1" v-if="data.staff_num_range">
                        {{ data.staff_num_range }}
                    </div>
                </div>
            </div>
            <div class="content">
                <p class="flex_start fs_14 color_999 use_tip mb_15">
                    <span class="label">信用代码：</span>
                    <span class="flex_1 text bold fs_14 color_666">
                        {{ data.creditcode }}
                    </span>
                </p>
                <p class="flex_start fs_14 color_999 use_tip mb_15">
                    <span class="label">公司官网：</span>
                    <a :href="data.website_link" target="_blank" class="flex_1 text bold fs_14 color_666 text_decoration btn" v-if="data.website_link != '-'">
                        {{ data.website_link }}
                    </a>
                    <span class="flex_1 text bold fs_14 color_666" v-else>{{ data.website_link }}</span>
                </p>
                <p class="flex_start fs_14 color_999 use_tip mb_15">
                    <span class="label">注册地址：</span>
                    <span class="flex_1 text bold fs_14 color_666">
                        {{ data.address }}
                    </span>
                </p>
                <p class="flex_start fs_14 color_999 use_tip mb_15">
                    <span class="label">注册资本：</span>
                    <span class="flex_1 text bold fs_14 color_666">
                        {{ data.registered }}
                    </span>
                </p>
                <p class="flex_start fs_14 color_999 use_tip mb_15">
                    <span class="label">法人代表：</span>
                    <span class="flex_1 text bold fs_14 color_666">
                        {{ data.represent }}
                    </span>
                </p>
                <p class="flex_start fs_14 color_999 use_tip mb_15">
                    <span class="label">企业类型：</span>
                    <span class="flex_1 text bold fs_14 color_666">
                        {{ data.bussiness_industry }}
                    </span>
                </p>
                <p class="flex_start fs_14 color_999 use_tip mb_15">
                    <span class="label">营业期限：</span>
                    <span class="flex_1 text bold fs_14 color_666">
                        {{ data.busniss_expire }}
                    </span>
                </p>
                <p class="flex_start fs_14 color_999 use_tip mb_15">
                    <span class="label">企业状态：</span>
                    <span class="flex_1 text bold fs_14 color_666">{{
						data.reg_status
					}}</span>
                </p>
                <p class="flex_start fs_14 color_999 use_tip mb_15">
                    <span class="label">经营范围：</span>
                    <span class="flex_1 text bold fs_14 color_666">
                        {{ data.business_scope }}
                    </span>
                </p>
            </div>
        </el-dialog>
    </div>
</template>
<script>
// 公司详情
export default {
	name: "companyInfo",
	data() {
		return {
			dialogVisible: false,
			id: 0,
			//职位列表
			data: {}
		}
	},
	mounted() { },
	methods: {
		init() {
			this.$axios({
				url: `/ent/v3/index/company/${this.id}`,
				method: "GET"
			}).then(res => {
				if (res.code === 200) {
					this.data = res.data
				}
			})
		}
	}
}
</script>
<style lang="less" scoped>
.color_5AB0FF {
    color: #5ab0ff;
}
.text_decoration {
    text-decoration: underline;
}
.header {
    padding-bottom: 20px;
}
.company_logo {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 3px solid #f2f2f2;
    background: #fff;
}
.cont {
    height: 0;
}
.flex_end_cen {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.but_body {
    display: table;
    margin: 0 auto;
    min-height: 45px;
}
.button {
    line-height: 18px;
    display: inline-table;
    border: 1px solid #e6e6e6;
    border-radius: 19px;
    padding: 10px 20px;
    margin: 0 5px 5px 0;
}
.content {
    padding: 30px 45px;
    background: #f8f8f8;
}
.label {
    width: 70px;
}
.label,
.text {
    line-height: 18px;
}
</style>
