import cookie from 'js-cookie'
const tokenTableName = 'ZHILU_AUTH'
const storage = 'cookie'

const ss = window.sessionStorage
const ls = window.localStorage

/**
 * @description 获取token
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getToken() {
	if (storage) {
		if ('localStorage' === storage) {
			return localStorage.getItem(tokenTableName)
		} else if ('sessionStorage' === storage) {
			return sessionStorage.getItem(tokenTableName)
		} else if ('cookie' === storage) {
			return cookie.get(tokenTableName)
		}
		return localStorage.getItem(tokenTableName)

	}
	return localStorage.getItem(tokenTableName)

}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setToken(token) {
	if (storage) {
		if ('localStorage' === storage) {
			return localStorage.setItem(tokenTableName, token)
		} else if ('sessionStorage' === storage) {
			return sessionStorage.setItem(tokenTableName, token)
		} else if ('cookie' === storage) {
			return cookie.set(tokenTableName, token, { expires: 15 }) // 15天后失效
		}
		return localStorage.setItem(tokenTableName, token)

	}
	return localStorage.setItem(tokenTableName, token)

}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken() {
	if (storage) {
		if ('localStorage' === storage) {
			return localStorage.removeItem(tokenTableName)
		} else if ('sessionStorage' === storage) {
			return sessionStorage.clear()
		} else if ('cookie' === storage) {
			return cookie.remove(tokenTableName)
		}
		return localStorage.removeItem(tokenTableName)

	}
	return localStorage.removeItem(tokenTableName)

}

/**
 * @description 获取sessionStorage
 * @returns {void|Promise<void>}
 */
export function s_getItem(key) {
	try {
		return JSON.parse(ss.getItem(key))
	} catch (err) {
		return null
	}
}

/**
 * @description 存储sessionStorage
 * @returns {void|Promise<void>}
 */
export function s_setItem(key, val) {
	ss.setItem(key, JSON.stringify(val))
}

/**
 * @description 删除sessionStorage
 */
export function s_removeItem(key) {
	ss.removeItem(key)
}

/**
 * @description 清空sessionStorage
 */
export function s_clear() {
	ss.clear()
}

/**
 * @description 获取localStorage
 */
export function l_getItem(key) {
	try {
		return JSON.parse(ls.getItem(key))
	} catch (err) {
		return null
	}
}

/**
 * @description 存储localStorage
 */
export function l_setItem(key, val) {
	ls.setItem(key, JSON.stringify(val))
}

/**
 * @description 删除localStorage
 */
export function l_removeItem(key) {
	ls.removeItem(key)
}

/**
 * @description 清空localStorage
 */
export function l_clear() {
	ls.clear()
}
