<template>
    <div class="telephone">
        <el-dialog v-model="dialogVisible" :lock-scroll="true" width="700px">
            <template v-slot:title>
                <div class="flex_c">
                    <p class="fs_18 bold">信息预览</p>
                </div>
            </template>
            <div class="content">
                <div class="flex user-info">
                    <el-avatar :src="data.user.avatar" :size="100" class="avatar" alt="头像">
                        <img :src="
								data.user.gender == '男'
									? $store.state.nanAvatar
									: $store.state.nvAvatar
							" />
                    </el-avatar>
                    <!-- <img src="@/assets/imgs/index/3.png" class="avatar" alt="头像" /> -->
                    <div class="flex_1">
                        <div class="flex">
                            <div class="flex_c">
                                <p class="fs_18 bold color_000 mr_10">
                                    {{ data.user.realname }}
                                </p>
                                <img v-if="data.user.verify_status == 1" src="@/assets/imgs/index/auth-tip.png" class="auth-tip" alt="已实名" />
                                <p class="fs_20 bold color_000">{{ data.user.mobile }}</p>
                            </div>
                            <div v-if="!data.user_invite_exists && showInvited" class="btn flex_cen msg" @click="handleDialog('invited')">
                                <img src="@/assets/imgs/icon/msg-icon.png" class="icon" alt="" />
                                <p class="fs_14 color">邀请面试</p>
                            </div>
                        </div>
                        <div class="flex_c mt_15">
                            <p class="info-tip" v-if="data.user.gender">
                                {{ data.user.gender }}
                            </p>
                            <p class="info-tip" v-if="data.user.age">{{ data.user.age }}</p>
                            <p class="info-tip" v-if="data.user.work_exp">
                                {{ data.user.work_exp }}
                            </p>
                            <p class="info-tip" v-if="data.user.education">
                                {{ data.user.education }}
                            </p>
                            <p class="info-tip" v-if="data.user.nation">
                                {{ data.user.nation }}
                            </p>
                        </div>
                        <div class="mt_10 fs_14 color_666 use_tip" v-if="data.user.current_addr || data.user.jnplace">
                            <span class="line" v-if="data.user.current_addr">所在地：{{ data.user.current_addr }}</span>
                            <span class="line" v-if="data.user.jnplace">籍贯：{{ data.user.jnplace }}</span>
                        </div>
                    </div>
                </div>
                <div class="leve-info fs_14" v-if="
						data.user.labels.length > 0 ||
							data.user.resign_cause.length > 0 ||
							data.user.work_time
					">
                    <p class="color_999 flex_c" v-if="data.user.labels.length > 0">
                        <span class="label">求职关注点：</span><span class="color_666 bold">
                            <span class="line" v-for="(item, index) in data.user.labels" :key="index">
                                {{ item }}{{ index == data.user.labels.length - 1 ? "" : "、" }}
                            </span>
                        </span>
                    </p>
                    <p class="color_999 flex_c mt_10" v-if="data.user.resign_cause.length > 0">
                        <span class="label">离职原因：</span>
                        <span class="color_666 bold">
                            <span class="line" v-for="(item, index) in data.user.resign_cause" :key="index">
                                {{ item
								}}{{ index == data.user.resign_cause.length - 1 ? "" : "、" }}
                            </span>
                        </span>
                    </p>
                    <p class="color_999 flex_c mt_10" v-if="data.user.work_time">
                        <span class="label">打算换工作：</span>
                        <span class="color_666 bold">
                            {{ data.user.work_time }}
                        </span>
                    </p>
                </div>
                <div class="job-list">
                    <div class="list-title">工作经历</div>
                    <template v-if="data.work_exp_list.length > 0">
                        <p class="use_tip fs_14 color_333 mb_10 flex_c one" v-for="(item, index) in data.work_exp_list" :key="index">
                            <span class="line color_5AB0FF btn one" v-if="item.company_id > 0" @click.stop="showCompany(item)">
                                {{ item.company_name }}
                            </span>
                            <span class="line one" v-else>
                                {{ item.company_name }}
                            </span>
                            <span class="line">{{ item.job_name }} <template v-if="item.resign_id">{{ item.resign_type==='in'?'':'(外包岗)' }}</template></span>
                            <span class="line">{{ item.start_time }}至{{ item.end_time }}</span>
                            <span class="line" v-if="item.work_year">在职{{ item.work_year }}
                            </span>
                            <img v-if="item.no8_pic" src="@/assets/imgs/icon/seal.png" class="seal-icon btn" alt="" @click="handleDialog('prove', item.no8_pic)" />
                        </p>
                    </template>
                    <template v-else>
                        <span class="sub_color">暂未填写！</span>
                    </template>
                </div>
                <div class="job-list">
                    <div class="list-title">教育经历</div>
                    <template v-if="data.edu_list.length > 0">
                        <p class="use_tip fs_14 color_333 mb_10" v-for="(item, index) in data.edu_list" :key="index">
                            <span class="line">{{ item.school_name }}</span>
                            <span class="line">{{ item.major }}</span>
                            <span class="line">{{ item.start_time }}至{{ item.end_time }}</span>
                            <span class="line">{{ item.education_text }}</span>
                        </p>
                    </template>
                    <template v-else>
                        <span class="sub_color">暂未填写！</span>
                    </template>
                </div>
                <div class="job-list">
                    <div class="list-title">自我评价</div>
                    <p class="fs_14 color_333 mb_10">
                        <span v-if="data.user.evaluation">{{ data.user.evaluation }}</span>
                        <span v-else class="sub_color">暂未填写！</span>
                    </p>
                </div>
            </div>
        </el-dialog>
        <!-- 发送面试邀请 -->
        <invited ref="invited"></invited>
        <!-- 离职证明申请 、 工作记录 -->
        <prove ref="prove"></prove>
        <company-info ref="companyInfo"></company-info>
    </div>
</template>

<script>
// 个人相关信息
import invited from "./invited.vue"
import prove from "./prove.vue"
import companyInfo from "./company-info.vue"
export default {
	components: { invited, prove, companyInfo },
	name: "resume",
	data() {
		return {
			id: 0,
			job: "",
			data: {},
			dialogVisible: false
		}
	},
	props: {
		showInvited: {
			default: true,
			type: Boolean
		}
	},

	methods: {
		handleDialog(dialog, pic) {
			this.dialogVisible = false
			this.$refs[dialog].id = this.id
			if (dialog == "invited") {
				if (this.data.invited_type == "intention") {
					this.$refs[dialog].id = this.data.job_id //替换为工作ID
					this.$refs[dialog].form.type = this.data.invited_type //替换为intention类型
				}
			} else {
				this.$refs.prove.pic = pic
			}
			this.$refs[dialog].init()
			this.$refs[dialog].dialogVisible = true
		},
		showCompany(item) {
			this.$refs.companyInfo.id = item.company_id
			this.$refs.companyInfo.init()
			this.$refs.companyInfo.dialogVisible = true
		}
	}
}
</script>

<style lang="less" scoped>
.color_5AB0FF {
    color: #5ab0ff;
}
/deep/.el-dialog__header {
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0;
}
.content {
    border-top: 1px solid #ddd;
    height: 62vh;
    padding: 50px;
    overflow-y: scroll;
    .user-info {
        .avatar {
            width: 100px;
            height: 100px;
            margin-right: 20px;
        }
        .auth-tip {
            width: inherit;
            height: 18px;
            margin-right: 18px;
        }
        .btn {
            width: 86px;
            height: 22px;
            border-radius: 2px;
            .icon {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }
        .info-tip {
            padding: 5px 12px;
            border-radius: 20px;
            background-color: #ededed;
            font-size: 14px;
            color: #666666;
            margin-right: 10px;
        }
    }
    .leve-info {
        margin-top: 20px;
        background: #f8f8f8;
        border: 1px solid #ededed;
        border-radius: 2px;
        padding: 16px 20px;
        .label {
            width: 95px;
        }
    }
    .job-list {
        .seal-icon {
            width: 70px;
        }
    }
    .list-title {
        color: #333;
        font-size: 16px;
        position: relative;
        margin: 20px 0 12px;
        &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background: #1890ff;
            border-radius: 1px;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>
