<template>
    <footer class="footer z1">
        <div class="top flex_cen">
            <div class="contain flex">
                <div class="flex_c flex_1">
                    <img src="@/assets/imgs/layout/sacn.png" class="icon" alt="扫码离职" />
                    <div>
                        <p class="fs_16 333">扫码离职</p>
                        <p class="fs_14 color_999 mt_10">
                            完善人事流程，以法规要求来降低风险
                        </p>
                        <!-- <p class="fs_14 color_999">标准化来构建产品的超级入口</p> -->
                    </div>
                </div>
                <div class="flex_1">
                    <div style="margin:0 auto;display:table">
                        <div class="flex_c">
                            <img src="@/assets/imgs/layout/share.png" class="icon" alt="共享交换" />
                            <div>
                                <p class="fs_16 color_333">共享交换</p>
                                <p class="fs_14 color_999 mt_10">
                                    即时共建共享，促进HR精准高效的招聘
                                </p>
                                <!-- <p class="fs_14 color_999">企业可在平台免费招聘其他人才</p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex_1">
                    <div></div>
                    <div class="flex_c">
                        <img src="@/assets/imgs/layout/free.png" class="icon" alt="永久免费" />
                        <div>
                            <p class="fs_16 color_333">永久免费</p>
                            <p class="fs_14 color_999 mt_10">永久免费交换，企业年省百万人事成本</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="contain flex flex_start relevant flex_1">
                <div class="links">
                    <p class="fs_16 bold color_999">友情链接</p>
                    <p style="margin-top:30px;">
                        <a href="http://www.mohrss.gov.cn/" target="_black" class="link fs_14">中国人力资源和社会保障部</a>
                        <a href="http://hrss.sz.gov.cn/" target="_black" class="link fs_14">深圳市人力资源和社会保障局</a>
                        <a href="http://www.szhra.org/index.aspx" target="_black" class="link fs_14">深圳市人力资源行业协会</a>
                    </p>
                </div>
                <div class="contact">
                    <div class="flex_c">
                        <img src="../../assets/imgs/layout/tel-icon.png" class="tel-icon" alt="" />
                        <div>
                            <p class="fs_16 color_999 bold">咨询热线</p>
                            <a href="tel:0755-36861683" class="fs_36 bold color_999 mt_5">0755-36861683</a>
                        </div>
                    </div>
                    <a href="mailto:info@zhiluinfo.com" class="flex_c" style="margin-top:40px;">
                        <img src="../../assets/imgs/layout/emil-icon.png" class="emil-icon" alt="" />
                        <span class="fs_16 color_999 bold">info@zhiluinfo.com</span>
                    </a>
                </div>
                <div class="wechat flex_c">
                    <div class="center" style="margin-right:20px;">
                        <!-- <p class="fs_16 bold color_444">微信扫一扫</p> -->
                        <div class="qr-img">
                            <img src="../../assets/imgs/layout/kf_qr.png" alt="微信客服" @click="serviceVisible=true" />
                        </div>
                        <p class="fs_14 color_999">微信客服</p>
                    </div>
                    <div class="center">
                        <!-- <p class="fs_16 bold color_999">微信扫一扫</p> -->
                        <div class="qr-img">
                            <img src="../../assets/imgs/layout/wxapp.jpg" alt="小程序二维码" @click="QrcodeVisible=true" />
                        </div>
                        <p class="fs_14 color_999">微信小程序</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="contain fs_14 color_999">
                Copyright © 2019-2021 深圳市职路信息技术有限公司 All Rights Reserved.
                备案号：粤ICP备18049241号-4
            </div>
        </div>
        <el-dialog v-model="QrcodeVisible" title="打开微信扫一扫" :lock-scroll="true" width="460px">
            <div class="center content">
                <div class="dialog-qr">
                    <img src="../../assets/imgs/layout/wxapp.jpg" alt="小程序二维码" />
                </div>
            </div>
        </el-dialog>
        <el-dialog v-model="serviceVisible" title="打开微信扫一扫" :lock-scroll="true" width="460px">
            <div class="center content">
                <div class="dialog-qr">
                    <img src="../../assets/imgs/layout/kf_qr.png" alt="微信客服二维码" />
                </div>
            </div>
        </el-dialog>
    </footer>
</template>

<script>
export default {
	name: "Footer",
	data() {
		return {
			QrcodeVisible: false,
			serviceVisible: false
		}
	},
	methods: {}
}
</script>

<style lang="less" scoped>
.footer {
    background: #444;
    .close-btn {
        width: 120px;
    }
    .dialog-qr {
        padding: 0 30px 30px;
    }
    .top {
        background: #fff;
        height: 160px;
        .icon {
            width: 67px;
            height: 67px;
            border-radius: 100%;
            margin-right: 10px;
        }
        .contain {
            .flex_1 {
                width: 33.33%;
            }
        }
    }
    .bottom {
        .relevant {
            padding: 30px 0 20px;
            .links {
                > div:not(:last-child) {
                    margin-right: 80px;
                }
                .link {
                    color: #999;
                    line-height: 30px;
                }
            }
            .contact {
                .tel-icon {
                    width: 74px;
                    height: 74px;
                    margin-right: -16px;
                }
                .emil-icon {
                    width: 20px;
                    margin-right: 8px;
                    margin-left: 32px;
                }
            }
            .wechat {
                .qr-img {
                    width: 90px;
                    height: 90px;
                    padding: 4px;
                    margin: 15px 0 6px;
                    background: #fff;
                    border-radius: 2px;
                    cursor: pointer;
                }
            }
        }
    }
    .copyright {
        div {
            border-top: 1px solid #505050;
            padding: 16px 0;
        }
    }
}
</style>
