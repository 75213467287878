<template>
    <div class="page-nav">
        <div class="contain flex">
            <div class="flex_c">
                <h1 class="LOGO">
                    <router-link to="/">
                        <img src="@/assets/imgs/layout/logo.png" alt="职路数字" />
                    </router-link>
                </h1>
                <nav class="flex_c">
                    <router-link to="/" class="nav-item btn" :class="{ active: title == '首页' }">首页</router-link>
                    <router-link to="/hall" class="nav-item btn" :class="{ active: title == '人才大厅' }">人才大厅</router-link>
                    <div class="nav-item btn" @click="authPath(['/position'], linkTo)" :class="{ active: title == '全职招聘' }">全职招聘</div>
                    <div class="nav-item btn" @click="authPath(['/employment'], linkTo)" :class="{ active: title == '灵活用工' }">灵活用工</div>
                    <div class="nav-item btn" @click="authPath(['/dimission'], linkTo)" :class="{ active: title == '离职办理' }">离职办理</div>
                    <div class="nav-item btn" @click="authPath(['/payTaxes'], linkTo)" :class="{ active: title == '完税申请' }" v-if="companyInfo.is_pay_taxe">薪税服务</div>
                </nav>
            </div>
            <div class="user flex_c">
                <div class="flex_cen btn color_333" @click="authPath([], invitedTabelVisible)">
                    <p class="calendar"></p>
                    <span class="fs_14">面试安排</span>
                    <invited-tabel ref="invitedTabel"></invited-tabel>
                </div>
                <div style="margin-left:50px;">
                    <div class="user-view">
                        <span class="el-dropdown-link btn" @click.stop.prevent="userInfoVisible">
                            {{ userInfo.realname || baseName
							}}<i class="el-icon--right" :class="
									showUserinfo ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
								"></i>
                        </span>
                        <el-collapse-transition>
                            <div class="dropdown-main" v-show="showUserinfo" @mouseleave="showUserinfo = false">
                                <div class="item info">
                                    <div class="flex">
                                        <router-link to="/account-info">
                                            <el-avatar :src="userInfo.avatar" :size="60" class="avatar" alt="头像">
                                                <img :src="$store.state.zhiluAvatar" />
                                            </el-avatar>
                                        </router-link>
                                        <div class="flex_1">
                                            <div class="flex">
                                                <div class="flex_c">
                                                    <router-link to="/account-info" class="fs_14 color_333">
                                                        {{ userInfo.realname || baseName }}
                                                    </router-link>
                                                    <img src="@/assets/imgs/icon/yz-icon.png" class="auth-icon" v-if="userInfo.verify_status" />
                                                    <img src="@/assets/imgs/icon/myz-icon.png" class="auth-icon" v-else />
                                                    <p class="fs_12 color_999">{{ userInfo.mobile }}</p>
                                                </div>
                                                <p class="flex_c fs_14 color_999 use_tip mr-24">
                                                    <router-link to="/account-info" class="line btn color_999">设置</router-link>
                                                    <span class="line btn" @click="loginOut">退出</span>
                                                </p>
                                            </div>
                                            <div class="flex_c fs_14 color_999 use_tip mt_10" v-if="userInfo.company_status !== 'no_apply'">
                                                <span class="line">{{ companyInfo.dept_name }}</span>
                                                <span class="line">{{ companyInfo.job_name }}</span>
                                            </div>
                                            <div class="flex_c fs_14 color_999 use_tip mt_10" v-else>
                                                <router-link to="/create" class="color" v-if="userInfo.company_status === 'no_apply'">去认证</router-link>
                                                <router-link to="/inReview" class="color" v-else>查看审核进度</router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="flex mt_20" v-if="userInfo.company_status === 'normal'">
                                        <p>
                                            <span class="fs_14 sub_color mr_10">已贡献部门CRC</span>
                                            <span class="fs_14 color">{{ companyInfo.credits || "0.00" }} CRC</span>
                                        </p>
                                        <p class="flex_c fs_14 color_666 use_tip mr-24">
                                            <span class="line btn" @click="rule">CRC规则</span>
                                            <span class="line btn" @click="crcTabel">CRC明细</span>
                                        </p>
                                    </div> -->
                                </div>
                                <div class="item company top_link" v-if="userInfo.company_status !== 'no_apply'">
                                    <div class="flex_c">
                                        <img src="@/assets/imgs/icon/company.png" class="mr_10" style="width:18px;" />
                                        <p class="fs_14 color_333">企业信息</p>
                                    </div>
                                    <div class="flex mt_10">
                                        <router-link to="/account" class="fs_14 bold color_333 btn">{{ companyInfo.company_name }}</router-link>
                                        <router-link to="/account?id=colleagues" class="btn" v-if="userInfo.company_status === 'normal'">企业同事
                                            <el-badge class="mark" :value="companyInfo.check_num" />
                                        </router-link>
                                        <router-link to="/inReview" class="color" v-else>查看审核进度</router-link>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-transition>
                    </div>
                </div>
            </div>
        </div>
        <rule-dialog ref="ruleDialog"></rule-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import ruleDialog from "@/components/rule-dialog"
import { removeToken, l_removeItem, s_clear } from "@/common/cache"
export default {
	name: "page-nav",
	components: { ruleDialog },
	data() {
		return {
			title: "",
			baseName: "职路用户",
			showUserinfo: false
		}
	},
	created() {
		this.title = this.$route.meta.title
		this.getUserInfo()
	},
	watch: {
		$route(n) {
			this.title = n.meta.title
		}
	},
	computed: {
		...mapGetters({
			userInfo: "getUserInfo",
			companyInfo: "getCompanyInfo"
		})
	},
	methods: {
		...mapActions({
			setUserInfo: "SETUSERINFO",
			setCompanyInfo: "SETCOMPANY"
		}),
		...mapMutations({
			get_userInfo: 'get_userInfo'
		}),
		getUserInfo() {
			this.get_userInfo()
		},
		invitedTabelVisible() {
			this.showUserinfo = false
			this.$refs.invitedTabel.showTabel = !this.$refs.invitedTabel.showTabel
			if (this.$refs.invitedTabel.showTabel) {
				this.$refs.invitedTabel.init()
			}
		},
		userInfoVisible() {
			this.showUserinfo = !this.showUserinfo
			this.$refs.invitedTabel.showTabel = false
		},
		rule() {
			this.showUserinfo = false
			this.$refs.ruleDialog.dialogVisible = true
		},
		crcTabel() {
			this.showUserinfo = false
			this.$router.push("/crc")
		},
		loginOut() {
			this.$confirm("退出登录?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				removeToken()
				l_removeItem("USERINFO")
				l_removeItem("COMPANY")
				s_clear()
				this.setCompanyInfo({})
				this.$router.push("/login")
			})
		},
		linkTo(path) {
			this.$router.push(path)
		}
	}
}
</script>

<style lang="less" scoped>
.page-nav {
    width: 100%;
    height: 80px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0 0 2px 1px #f6f6f6;
    .mr-24 {
        margin-right: -24px;
    }
    .contain {
        height: 80px;
        // line-height: 80px;
        max-width: 1200px;
        min-width: 1000px;
        width: auto;
        .LOGO {
            width: 143px;
            // height: 41px;
            margin-right: 96px;
            font-size: 18px;
        }
        .nav-item {
            line-height: 80px;
            margin-right: 32px;
            font-size: 16px;
            color: #000;
            transition: color .2s ease;
            &:hover {
                color: #1890ff;
                transition: color .2s ease;
            }
            &.active {
                position: relative;
                color: #1890ff;
                &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                    width: 100%;
                    height: 3px;
                    border-radius: 2px;
                    background: #1890ff;
                }
            }
        }
        .user {
            .calendar {
                width: 14px;
                height: 14px;
                margin-right: 8px;
                background: url(../../assets/imgs/layout/calendar.png);
                background-size: cover;
                transition: background 0.2s ease;
            }
            .btn {
                position: relative;
                transition: color 0.2s ease;
            }
            .btn:hover {
                color: #1890ff;
                transition: color 0.2s ease;
                .calendar {
                    background: url(../../assets/imgs/layout/calendar1.png);
                    background-size: cover;
                    transition: background 0.2s ease;
                }
            }
            .user-view {
                position: relative;
                .dropdown-main {
                    width: 420px;
                    border-radius: 2px;
                    background-color: #fff;
                    box-shadow: 0px 0px 6px rgba(149, 149, 149, 0.16);
                    position: absolute;
                    top: 28px;
                    right: -10px;
                    z-index: 9;
                    .item {
                        padding: 20px 30px;
                    }
                    .info {
                        .avatar {
                            width: 60px;
                            height: 60px;
                            border-radius: 100%;
                            margin-right: 10px;
                        }
                        .auth-icon {
                            width: 15px;
                            margin: 0 10px 0 5px;
                        }
                    }
                    .company {
                        /deep/.el-badge__content--primary {
                            background-color: #ff4e4e;
                        }
                    }
                }
            }
        }
    }
}
</style>
