<template>
    <div class='layout  flex_col'>
        <page-nav ref="pageNav"></page-nav>
        <div class="main flex_1" @click="hideUserInfo">
            <router-view></router-view>
        </div>
        <pay-vip-dialog></pay-vip-dialog>
        <page-footer></page-footer>
    </div>
</template>

<script>
import pageNav from '@/components/layout/page-nav.vue'
import pageFooter from '@/components/layout/page-footer.vue'
export default {
	components: { pageNav, pageFooter },
	watch: {
		$route() {
			window.scroll(0, 0)
		}
	}
}
</script>

<style lang='less' scoped>
.layout {
    height: 100%;
    >.main {
        min-height: 88vh;
    }
}
</style>