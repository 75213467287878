import { createStore } from "vuex"
import axios from './request/axios'
import { l_setItem } from "@/common/cache"

export default createStore({
	state: {
		name: "职路数字",
		baseLogo: require('@/assets/imgs/baseLogo.png'),
		baseAvatar: require('@/assets/imgs/baseAvatar.png'),
		zhiluAvatar: require('@/assets/imgs/layout/zhilu.png'),
		nanAvatar: require('@/assets/imgs/base-nan.jpg'),
		nvAvatar: require('@/assets/imgs/base-nv.jpg'),
		configure: {}, // 配置信息
		companyVips: [], // 企业会员信息
		userInfo: {}, // 用户信息
		companyInfo: {}, // 企业信息
		showVipModal: false // 显示购买会员弹窗
	},
	mutations: {
		set_userInfo(state, data) {
			state.userInfo = data
		},
		set_companyInfo(state, data) {
			state.companyInfo = data
		},
		set_showVipModal(state, data) {
			state.showVipModal = data
		},
		get_userInfo(state) {
			axios({
				url: "/ent/v3/usercenter/info",
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					let { user, member } = res.data

					state.userInfo = user
					l_setItem("USERINFO", user)

					if (user.company_status !== "no_apply") {
						l_setItem("COMPANY", member)
						state.companyInfo = member
					} else {
						l_setItem("COMPANY", {})
						state.companyInfo = {}
					}
				}
			})
		}
	},
	actions: {
		SETUSERINFO({ commit }, data) {
			commit("set_userInfo", data)
		},
		SETCOMPANY({ commit }, data) {
			commit("set_companyInfo", data)
		}
	},
	getters: {
		getConfigure(state) {
			if(JSON.stringify(state.configure) !== '{}') {
				return state.configure
			}
			axios({
				url: "/ent/v3/index/setting",
				method: "GET",
				hideLoading: true
			}).then(res=>{
				if(res.code === 200) {
					state.configure = res.data.setting
					state.companyVips = res.data.company_order
					return state.configure
				}
			})
		},
		getCompanyVips(state) {
			if(state.companyVips.length) {
				return state.companyVips
			}
			axios({
				url: "/ent/v3/index/setting",
				method: "GET",
				hideLoading: true
			}).then(res=>{
				if(res.code === 200) {
					state.configure = res.data.setting
					state.companyVips = res.data.company_order
					return state.companyVips
				}
			})
		},
		getUserInfo: state => state.userInfo,
		getCompanyInfo: state => state.companyInfo,
		showVipModal: state => state.showVipModal
	}
})
