import {
	createRouter,
	createWebHistory
} from "vue-router"

import Layout from "@/views/layout.vue"
// import store from '../store'
import {
	getToken,
	l_getItem
} from "@/common/cache"

const routes = [ {
	path: "/login",
	name: "Login",
	component: () => import("@/views/author/login"),
	meta: {
		title: "登录",
		auth: false,
		neverLogin: true
	}
},
{
	path: "/bind",
	name: "Bind",
	component: () => import("@/views/author/bind"),
	meta: {
		title: "绑定手机号",
		auth: false
	}
},
{
	path: "/agreement",
	name: "Agreement",
	component: () => import("@/views/author/agreement"),
	meta: {
		title: "职路用户服务协议",
		auth: false,
		neverLogin: true
	}
},
{
	path: "/invite",
	name: "Invite",
	component: () => import("@/views/share/index"),
	meta: {
		title: "邀请好友",
		auth: false,
		neverLogin: true
	}
},
{
	path: "/company/invite",
	name: "CompanyInvite",
	component: () => import("@/views/share/company"),
	meta: {
		title: "邀请企业",
		auth: false,
		neverLogin: true
	}
},
{
	path: "/",
	redirect: "/index",
	component: Layout,
	children: [ {
		path: "/index",
		name: "Index",
		component: () => import("@/views/index"),
		meta: {
			title: "首页",
			auth: false
		}
	},
	{
		path: "/statistics",
		name: "Statistics",
		component: () => import("@/views/index/statistics"),
		meta: {
			title: "数据面板",
			auth: false
		}
	},

	{
		path: "/hall",
		name: "Hall",
		component: () => import("@/views/talents-hall"),
		meta: {
			title: "人才大厅",
			auth: false
		}
	},
	{
		path: "/position",
		name: "Position",
		component: () => import("@/views/position"),
		meta: {
			title: "全职招聘",
			auth: false
		}
	},
	{
		path: "/position/post",
		name: "PositionPost",
		component: () => import("@/views/position/post"),
		meta: {
			title: "全职招聘",
			subtitle: "发布新职位",
			auth: true
		}
	},
	{
		path: "/position/collection/:id/:title",
		name: "PositionCollection",
		component: () => import("@/views/position/collection"),
		meta: {
			title: "全职招聘",
			subtitle: "已投递",
			auth: true
		}
	},
	{
		path: "/position/entry/:id/:title",
		name: "PositionEntry",
		component: () => import("@/views/position/entry"),
		meta: {
			title: "全职招聘",
			subtitle: "已入职",
			auth: true
		}
	},
	{
		path: "/dimission",
		name: "Dimission",
		component: () => import("@/views/dimission"),
		meta: {
			title: "离职办理",
			auth: false
		}
	},
	{
		path: "/bulkImport/:type",
		name: "BulkImport",
		component: () => import("@/views/dimission/bulkImport"),
		meta: {
			title: "批量导入",
			subtitle: "批量导入",
			auth: true
		}
	},
	{
		path: "/import-batch",
		name: "importBatch",
		component: () => import("@/views/dimission/import-batch"),
		meta: {
			title: "导入批次",
			auth: true
		}
	},
	{
		path: "/employment",
		name: "Employment",
		component: () => import("@/views/employment"),
		meta: {
			title: "灵活用工",
			auth: false
		}
	},
	{
		path: "/employment/post",
		name: "EmploymentPost",
		component: () => import("@/views/employment/post"),
		meta: {
			title: "灵活用工",
			subtitle: "发布新任务",
			auth: true
		}
	},
	{
		path: "/employment/upload-protocol/:id",
		name: "UploadProtocol",
		component: () => import("@/views/employment/upload-protocol"),
		meta: {
			title: "灵活用工",
			subtitle: "上传任务协议",
			auth: true
		}
	},
	{
		path: "/employment/setting/:id",
		name: "Setting",
		component: () => import("@/views/employment/setting"),
		meta: {
			title: "灵活用工",
			subtitle: "上传任务协议",
			auth: true
		}
	},
	{
		path: "/employment/preview/:id",
		name: "Preview",
		component: () => import("@/views/employment/preview"),
		meta: {
			title: "灵活用工",
			subtitle: "上传任务协议",
			auth: true
		}
	},
	{
		path: "/employment/task/:id/:name",
		name: "EmploymentTask",
		component: () => import("@/views/employment/task"),
		meta: {
			title: "灵活用工",
			subtitle: "管理任务",
			auth: true
		}
	},
	{
		path: "/special/batch/:id/:name",
		name: "SpecialBatch",
		component: () => import("@/views/special/batch"),
		meta: {
			title: "灵活用工",
			subtitle: "打款管理",
			auth: true
		}
	},
	{
		path: "/employment/runRecord/:id/:reward_id/:status",
		name: "EmploymentRunRecord",
		component: () => import("@/views/employment/runRecord"),
		meta: {
			title: "灵活用工",
			subtitle: "经营日志",
			auth: true
		}
	},
	{
		path: "/employment/pay-list/:id/:reward_id/:status",
		name: "EmploymentPayList",
		component: () => import("@/views/employment/pay-list"),
		meta: {
			title: "灵活用工",
			subtitle: "支出记录",
			auth: true
		}
	},
	{
		path: "/position/invite/:id/:title",
		name: "PositionInviteAudition",
		component: () => import("@/views/position/invite-audition"),
		meta: {
			title: "全职招聘",
			subtitle: "已邀请面试",
			auth: true
		}
	},
	{
		path: "/payTaxes",
		name: "Taxes",
		redirect: '/payTaxes/list',
		component: () => import("@/views/pay-taxes"),
		meta: {
			title: "完税申请",
			auth: false
		},
		children: [ {
			path: "auth",
			name: "TaxesAuth",
			component: () => import("@/views/pay-taxes/auth"),
			meta: {
				title: "完税申请",
				auth: false
			}
		},
		{
			path: "list",
			name: "TaxesList",
			component: () => import("@/views/pay-taxes/list"),
			meta: {
				title: "完税申请",
				auth: false
			}
		},
		{
			path: "add",
			name: "TaxesAdd",
			component: () => import("@/views/pay-taxes/add"),
			meta: {
				title: "完税申请 ",
				subtitle: "提交完税申请",
				auth: true
			}
		},
		{
			path: "check/:id",
			name: "TaxesCheck",
			component: () => import("@/views/pay-taxes/check"),
			meta: {
				title: "完税申请 ",
				subtitle: "提交完税申请",
				auth: true
			}
		},
		{
			path: "order/:id",
			name: "TaxesOrder",
			component: () => import("@/views/pay-taxes/order"),
			meta: {
				title: "完税申请 ",
				subtitle: "企业支付",
				auth: true
			}
		},
		{
			path: "detail/:id/:number",
			name: "TaxesDetail",
			component: () => import("@/views/pay-taxes/detail"),
			meta: {
				title: "完税申请 ",
				subtitle: "开票详情",
				auth: true
			}
		},
		{
			path: "bulkImport",
			name: "TaxesBulkImport",
			component: () => import("@/views/pay-taxes/bulkImport"),
			meta: {
				title: "完税申请 ",
				subtitle: "批量申请",
				auth: true
			}
		}
		]
	},
	{
		path: "/auth",
		name: "Auth",
		component: () => import("@/views/company-auth"),
		meta: {
			title: "企业认证",
			auth: false
		}
	},
	{
		path: "/create",
		name: "Create",
		component: () => import("@/views/company-auth/create"),
		meta: {
			title: "创建企业",
			auth: false
		}
	},
	{
		path: "/inReview",
		name: "InReview",
		component: () => import("@/views/company-auth/inReview"),
		meta: {
			title: "企业认证",
			auth: false
		}
	},
	// {
	// 	path: "/crc",
	// 	name: "CRC",
	// 	component: () => import("@/views/user/crc-details"),
	// 	meta: {
	// 		title: "个人信息",
	// 		subtitle: "CRC明细",
	// 		auth: true
	// 	}
	// },
	{
		path: "/account",
		name: "Account",
		component: () => import("@/views/user/account"),
		meta: {
			title: "企业信息",
			auth: true
		}
	},
	{
		path: "/strategic",
		name: "Strategic",
		component: () => import("@/views/user/strategic"),
		meta: {
			title: "战略伙伴",
			auth: true
		}
	},
	{
		path: "/account-info",
		name: "AccountInfo",
		component: () => import("@/views/user/account-info"),
		meta: {
			title: "个人信息修改",
			auth: false
		}
	}
	]
},
{
	path: "/404",
	name: "404",
	component: () => import("@/views/404"),
	meta: {
		auth: false
	}
},
{
	path: "/:pathMatch(.*)",
	redirect: "/404",
	meta: {
		auth: false
	}
}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})
router.beforeEach((to, from, next) => {
	let token = getToken()
	let userInfo = l_getItem("USERINFO") || {}
	let company = l_getItem("COMPANY") || {}

	let pageTitle = to.meta.title + ' - 职路数字'
	if (to.meta.subtitle) {
		pageTitle = to.meta.subtitle + ' - ' + pageTitle
	}
	document.title = pageTitle

	if (!token && !to.meta.neverLogin) {
		next("/login")
		return
	} else if (userInfo.company_status === 'no_apply' && to.meta.auth) {
		// 未注册企业
		next("/create")
		return
	} else if (userInfo.company_status === 'apply' && to.meta.auth) {
		// 企业办理申请中
		next("/inReview")
		return
	}

	// 未开启薪税服务
	if (to.name.includes('Taxes') && !company.is_pay_taxe) {
		next("/")
		return
	}

	next()
})
export default router