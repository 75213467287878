<template>
    <div class="">
        <el-dialog v-model="dialogVisible" :lock-scroll="true" custom-class="Invited" width="660px" top="10vh">
            <template v-slot:title>
                <div class="flex_c bgf">
                    <p class="fs_18 bold">发送面试邀请</p>
                </div>
            </template>
            <el-form ref="form" :model="form">
                <div class="content">
                    <div class="flex_end Invited-tabel">
                        <div class="btn fs_14 flex_c color" @click="showTabel">
                            <img src="@/assets/imgs/layout/calendar1.png" class="rili-icon" alt="" />
                            <p>面试安排</p>
                            <invited-tabel ref="invitedTabel"></invited-tabel>
                        </div>
                    </div>
                    <div class="tabel">
                        <p class="flex row">
                            <span class="label">面试人员</span>
                            <el-input placeholder="请填写面试人员姓名" class="value flex_1 flex_c btn" :value="data.realname" readonly></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">公司名称</span>
                            <el-input placeholder="请填写公司名称" class="value flex_1 flex_c btn" v-model.trim="data.company_name" readonly></el-input>
                        </p>
                        <p class="flex row" v-if="form.type == 'intention'">
                            <span class="label">面试职位</span>
                            <el-input class="value flex_1 flex_c btn" v-model.trim="data.job_name" readonly></el-input>
                        </p>
                        <p class="flex row" v-else>
                            <span class="label">面试职位</span>
                            <el-select v-model="form.job_id" placeholder="请选择面试职位" class="value flex_1 flex_c">
                                <el-option v-for="(item, index) in data.job_lst" :key="index" :value="item.id" :label="item.job_name">
                                </el-option>
                            </el-select>
                        </p>
                        <p class="flex row">
                            <span class="label">面试时间</span>
                            <el-date-picker v-model="form.pick_time" popper-class="customEvent" type="datetime" @change="dateChange" @focus="inputFocus" @blur="inputBlur" :default-value="defaultTime" :disabledDate="disabledDate" class="value flex_1 datetime" placeholder="请选择面试时间"></el-date-picker>
                        </p>
                        <p class="flex row">
                            <span class="label">面试地点</span>
                            <el-input placeholder="请填写面试地点" class="value flex_1 flex_c" v-model.trim="form.address"></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">联系人员</span>
                            <el-input placeholder="请填写联系人员姓名" class="value flex_1 flex_c" v-model.trim="form.contacts_name"></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">联系方式</span>
                            <el-input placeholder="请填写联系方式" class="value flex_1 flex_c" type="tel" v-model.trim="form.contacts_tel"></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">填写备注</span>
                            <el-input placeholder="注意事项以及准备材料" class="value flex_1 flex_c" v-model.trim="form.remark"></el-input>
                        </p>
                    </div>
                </div>
                <div class="footer bgf flex_cen">
                    <img src="../../../assets/imgs/invited-bg.png" class="invited-bg" alt="" />
                    <div class="flex_around">
                        <el-button type="info" size="medium" class="cancel" @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" size="medium" :loading="loading" @click="submit">发 送</el-button>
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
// 面试邀请
export default {
	name: "Invited",
	data() {
		return {
			id: 0,
			dialogVisible: false,
			show: false,
			loading: false,
			defaultTime: 0, // 默认面试时间
			isDefault: true,
			form: {
				type: "user",
				job_id: null,
				address: null,
				pick_time: null,
				contacts_name: null,
				contacts_tel: null,
				remark: null
			},
			//职位列表
			data: {
				realname: null,
				company_name: null,
				job_name: null,
				address: null,
				hr_mobile: null,
				hr_realname: null,
				job_lst: [ {} ] //设置默认值
			}
		}
	},
	mounted() {
		// 日期默认今天， 时间项默认 09:00:00
		let t = this.createTimeStr(new Date(), 'ymd') + ' 09:00:00'
		this.defaultTime = new Date(t) // Thu Jun 10 2021 09:00:00 GMT+0800 (中国标准时间)
	},
	methods: {

		init() {
			this.$axios({
				url: `/ent/v3/talent/invite/${this.id}`,
				method: "GET",
				data: {
					type: this.form.type
				}
			}).then(res => {
				if (res.code === 200) {
					this.data = res.data
					// this.form.job_id = this.data.job_lst[0].id //默认选上列表第一个
					this.form.contacts_name = this.data.hr_realname
					this.form.contacts_tel = this.data.hr_mobile
					this.form.address = this.data.address
				}
			})
		},
		close() {
			this.dialogVisible = false
		},
		submit() {
			let msg = ""
			if (this.form.type == "user" && !this.form.job_id) msg = "请选择面试职位"
			else if (!this.form.pick_time) msg = "请选择面试时间"
			else if (!this.form.address) msg = "请填写面试地点"
			else if (!this.form.contacts_name) msg = "请填写联系人员姓名"
			else if (!this.form.contacts_tel) msg = "请填写联系方式"
			if (msg) {
				this.$message.warning(msg)
			} else {
				this.loading = true
				this.$axios({
					url: `/ent/v3/talent/invite/${this.id}`,
					method: "POST",
					data: this.form,
					hideLoading: true
				}).then(res => {
					setTimeout(() => {
						this.loading = false
					}, 900)
					if (res.code === 200) {
						this.$message.success(res.msg)
						this.dialogVisible = false
						// if (this.$parent.name === "collection" || this.$parent.name === "hall") {
						// 	this.$parent.childInit()
						// } else if (this.$parent.$parent.name === "collection" || this.$parent.name === "resume") {
						// 	this.$parent.$parent.childInit()
						// }

						if(this.$parent.childInit) {
							console.log('this.$parent')
							this.$parent.childInit()
						}else {
							console.log('this.$parent.$parent')
							this.$parent.$parent.childInit()
						}
					}
				})

			}
		},
		disabledDate(time) {
			return time.getTime() < Date.now() - 8.64e7
		},
		showTabel() {
			this.$refs.invitedTabel.showTabel = !this.$refs.invitedTabel.showTabel

			if (this.$refs.invitedTabel.showTabel) {
				this.$refs.invitedTabel.init()
			}
		},
		// date-picker控件操作
		dateChange(time) {
			// 选择日期时，时间默认 09:00:00
			if (this.isDefault) {
				let t = this.createTimeStr(time, 'ymd') + ' 09:00:00'
				this.form.pick_time = new Date(t)
			}
		},
		inputFocus() {
			let that = this
			let customEvent = document.querySelectorAll('.customEvent input')[1]
			// 时间控件聚焦时，移除默认赋值
			customEvent.addEventListener('focus', function () {
				that.isDefault = false
			})
		},
		inputBlur() {
			// 时间控件失去焦点时，设置默认赋值
			this.isDefault = true
		},
		createTimeStr(time = new Date(), type = 'ymdhis') {
			let date = new Date(time)
			let Str = ''
			let year = date.getFullYear()
			let month = date.getMonth() + 1
			if (month < 10) month = '0' + month
			let day = date.getDate()
			if (day < 10) day = '0' + day
			let hours = date.getHours()
			if (hours < 10) hours = '0' + hours
			let minutes = date.getMinutes()
			if (minutes < 10) minutes = '0' + minutes
			let Seconds = date.getSeconds()
			if (Seconds < 10) Seconds = '0' + Seconds

			if (type === 'ymdhis') {
				Str = year + '-' +
                    month + '-' +
                    day + ' ' +
                    hours + ':' +
                    minutes + ':' +
                    Seconds
			} else if (type === 'ymd') {
				Str = year + '-' +
                    month + '-' +
                    day
			} else if (type === 'his') {
				Str = hours + ':' +
                    minutes + ':' +
                    Seconds
			}
			return Str
		}
	}
}
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0;
}
/deep/.Invited {
    background: transparent;
    padding: 0 30px;
    box-shadow: none;
    .el-dialog__header {
        background: #fff;
        border-radius: 2px 2px 0 0;
        .el-dialog__headerbtn {
            right: 50px;
        }
    }
    .Invited-tabel {
        position: relative;
    }
    .content {
        border-top: 1px solid #ddd;
        background: #fff;
        padding: 20px 42px 42px;
        position: relative;
        z-index: 3;
        .rili-icon {
            width: 14px;
            height: 14px;
            margin-right: 8px;
        }
        .tabel {
            margin-top: 10px;
            border: 1px solid #f1f1f1;
            border-bottom: none;
            .row {
                border-bottom: 1px solid #f1f1f1;
                .label {
                    width: 94px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    border-right: 1px solid #f1f1f1;
                }
                .datetime {
                    line-height: 50px;
                }
                .el-input__inner {
                    border: none;
                }
                .value {
                    height: 50px;
                    padding: 0 5px;
                }
                .select-trigger {
                    width: 100%;
                }
                .el-input--prefix .el-input__inner {
                    padding-left: 15px;
                }
                .el-input__prefix {
                    display: none;
                }
            }
        }
    }
    .footer {
        position: relative;
        height: 136px;
        .invited-bg {
            position: absolute;
            width: 660px;
            height: 200px;
            left: -30px;
            bottom: 0px;
            z-index: 0;
        }
        .el-button {
            width: 200px;
            height: 50px;
            font-size: 18px;
            position: relative;
            z-index: 9;
            margin: 0 20px;
            &.cancel {
                background: #fff;
                color: #1890ff;
                border-color: #fff;
            }
        }
    }
}
</style>
