<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
window.addEventListener('unload', ()=>{
	/* 监听页面刷新，返回顶部 */
	window.scrollTo(0, 0)
})

import { fetchQuitUrge, fetchCompanyStatus } from '@/common/scheduledTask'

export default {
	name: 'App',
	data() {
		return {
			timer: null
		}
	},
	created() {
		this.fetchData()
		this.timer = setInterval(()=>{
			this.fetchData()
		}, 1000 *60)
	},
	beforeUnmount() {
		this.timer && window.clearInterval(this.timer)
	},
	methods: {
		fetchData() {
			fetchQuitUrge()
			fetchCompanyStatus()
		}
	}
}
</script>

<style lang="less">
@import "./assets/style/base.less";
#app {
    height: 100%;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    color: #232323;
}
</style>
