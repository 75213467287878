<!--
 * @Author: WeChat: TashiDelek668
 * @Date: 2020-12-30 12:04:11
 * @LastEditors: TashiDelek
 * @LastEditTime: 2021-01-11 18:04:59
 * @FilePath: \hr_admin_2.0\src\components\layout\search-bar.vue
-->
<template>
    <div class="flex mb_10" style="height:40px">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :class="{active: $route.meta.subtitle}" @click="$route.meta.subtitle?$router.go(-1):''">{{$route.meta.title}}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="$route.meta.subtitle">{{$route.meta.subtitle}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div v-show="searchInput">
            <el-input :placeholder="placeholder" class="fs_14" ref="_search" v-model="keywords" name="keywords" @keyup.enter="search">
                <template #suffix>
                    <i class="el-icon-search btn" @click="handleClick"></i>
                </template>
            </el-input>
        </div>
    </div>
</template>

<script>
export default {
	name: 'searchBar',
	data() {
		return {
			keywords: '',
			autofocus: false
		}
	},
	props: {
		searchInput: {
			type: Boolean,
			default: true
		},
		placeholder: {
			type: String,
			default: '输入搜索关键词'
		}
	},
	methods: {
		handleClick() {
			if(this.keywords==this.$parent.keywords)return
			this.$emit('search', this.keywords)
		},
		search() {
			if(this.keywords==this.$parent.keywords)return
			var input = this.$refs._search
			input.blur()
			this.$emit('search', this.keywords)
		}
	}
}
</script>

<style lang='less' scoped>
/deep/.el-input__inner {
    width: 340px;
    height: 40px;
    border: none;
    padding-right: 40px;
}
.el-icon-search {
    line-height: 40px;
}
/deep/.el-input__suffix {
    right: 15px;
}
/deep/.el-icon-search {
    font-size: 20px;
}
/deep/.active {
    cursor: pointer;
    &:hover .el-breadcrumb__inner {
        color: #409eff !important;
    }
}
</style>