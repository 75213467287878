import axios from 'axios'
import qs from 'qs'
import router from '../router.js'
import {
	getToken,
	removeToken,
	s_setItem,
	s_getItem,
	l_removeItem
} from '@/common/cache'
import {
	ElMessage,
	ElLoading
} from 'element-plus'

axios.defaults.timeout = 60 * 1000
axios.defaults.withCredentials = false

let no_carryToken = false // 是否需要 token
let loading = null
let CancelToken = axios.CancelToken
let _cancel // 终止axios请求


// 请求拦截
axios.interceptors.request.use(config => {
	if (!no_carryToken && !getToken()) {
		// 终止请求
		_cancel()
		router.replace('/login')
	}
	//设置统一请求头
	// config.headers = {
	// 	'Content-Type': 'application/x-www-form-urlencoded',
	// 	'Authorization': 'Bearer ' + getToken()
	// }
	return config
}, error => {
	ElMessage.error('请求异常！' + error)
	return Promise.reject('请求异常', error)
})

//响应拦截器
axios.interceptors.response.use(res => {
	if (res.status === 200) {
		return Promise.resolve(res)
	}
	return Promise.reject(res)

}, error => {
	// const { message } = error
	// if (!message) {
	// 	ElMessage.error('系统异常')
	// }
	if (error) {
		return Promise.reject(error)
	}
	// 处理断网的情况
	ElMessage.error('网络异常,稍后重试！')

})


// 封装http请求
function baseRequest(params) {
	if(params._cancel) {
		_cancel && _cancel()
	}
	loading && loading.close()
	let hideLoading = params.hideLoading || false
	if (!hideLoading) {
		loading = ElLoading.service({
			lock: true,
			text: '努力加载中...',
			target: '#app',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.36)',
			customClass: 'myLoading'
		})
	}

	no_carryToken = params.no_carryToken || false // 默认请求头携带 token

	let hideMessage = params.hideMessage || false
	let method = params.method || 'GET'
	let data = params.data || {}
	let datatype = 'params'
	if (method === 'POST' || method === 'PUT') {
		datatype = 'data'
		data = qs.stringify(data)
	}
	let BASE_API = params.https ? params.https : `${process.env.VUE_APP_API}${params.url}`
	// BASE_API = 'https://zlh.zhiluinfo.com' + params.url

	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Authorization': 'Bearer ' + getToken()
	}
	if(params.headers) {
		headers = params.headers
	}

	return new Promise((resolve, reject) => {
		axios({
			url: BASE_API,
			method,
			headers,
			[datatype]: data,
			// 设置 cancelToken 可终止请求操作
			cancelToken: new CancelToken(function executor(c) {
				_cancel = c
			})
		}).then(res => {
			loading && loading.close()
			if (res.data.code !== 200) {
				if (res.data.code === 410000) {
					if(!s_getItem('showTost')) {
						s_setItem('showTost', true)
						ElMessage.warning('请登录')
						removeToken()
						l_removeItem('USERINFO')
						router.push('/login')
					}
				} else {
					!hideMessage && ElMessage.error(res.data.msg)
				}
			}
			resolve(res.data)
		}).catch((error) => {
			loading && loading.close()
			// ElMessage.error('系统开小差了，请稍后再试！')
			reject(error)
		})
	})
}

let timeOut = null
/* 防抖封装 */
export default function request(params) {

	let shake = params.shake || false // 是否防抖
	let shakeTime = params.shakeTime || 900 //默认防抖时间1000毫秒
	if (shake) {
		return new Promise((resolve) => {
			timeOut && clearTimeout(timeOut)
			let timer = !timeOut
			if (timer) {
				baseRequest(params).then(res => {
					resolve(res)
				})
			}
			timeOut = setTimeout(() => {
				timeOut = null
			}, shakeTime)

			// timeOut = setTimeout(() => {
			// 	baseRequest(params).then(res => {
			// 		resolve(res)
			// 	})
			// }, shakeTime)
		})
	}
	return baseRequest(params)

}