/**
 * 图片路径转成canvas
 * @param {图片url} url
 */
async function imgToCanvas(url) {
	// 创建img元素
	const img = document.createElement("img")
	img.src = url
	img.setAttribute("crossOrigin", "anonymous") // 防止跨域引起的 Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
	await new Promise((resolve) => { img.onload = resolve })
	// 创建canvas DOM元素，并设置其宽高和图片一样
	const canvas = document.createElement("canvas")
	canvas.width = img.width > 992 ? 992 : img.width
	canvas.height = img.height > 1402 ? 1402 : img.height
	// 坐标(0,0) 表示从此处开始绘制，相当于偏移。
	canvas.getContext("2d").drawImage(img, 0, 0, 992, 1402)
	return canvas
}

/**
 * canvas添加水印
 * @param {canvas对象} canvas
 * @param {水印文字} text
 */
function addWatermark(canvas, text) {
	const ctx = canvas.getContext("2d")
	ctx.fillStyle = "rgba(200, 200, 200, 0.2)"
	ctx.font = "26pt Calibri"
	ctx.textBaseline = "middle"
	ctx.rotate(-20 * Math.PI / 180)
	// for (var i = 0; i < 10; i++) {
	// 	var x = Math.floor(Math.random() * 900 + 100)
	// 	var y = Math.floor(Math.random() * 1800)
	// 	ctx.fillText(text, x, y)
	// }
	ctx.fillText(text.company, 100, 300)
	ctx.fillText(text.info, 100, 340)
	ctx.fillText(text.company, -300, 1300)
	ctx.fillText(text.info, -300, 1340)
	ctx.fillText(text.company, 140, 520)
	ctx.fillText(text.info, 140, 560)
	ctx.fillText(text.company, 50, 1500)
	ctx.fillText(text.info, 50, 1540)
	ctx.fillText(text.company, 600, 1000)
	ctx.fillText(text.info, 600, 1040)
	ctx.fillText(text.company, -80, 1700)
	ctx.fillText(text.info, -80, 1740)
	ctx.fillText(text.company, 10, 1100)
	ctx.fillText(text.info, 10, 1140)
	ctx.fillText(text.company, 10, 900)
	ctx.fillText(text.info, 10, 940)
	ctx.fillText(text.company, 10, 680)
	ctx.fillText(text.info, 10, 720)
	return canvas
}

/**
 * canvas转成img
 * @param {canvas对象} canvas
 */
function convasToImg(canvas) {
	// 新建Image对象，可以理解为DOM
	// var image = new Image()
	// canvas.toDataURL 返回的是一串Base64编码的URL
	// 指定格式 PNG
	// image.src = canvas.toDataURL("image/png")
	return canvas.toDataURL("image/png")
}

async function createWater(imgUrl, text, cb) {
	// const imgUrl =
	//   "https://cdn.jsdelivr.net/gh/zhangpanfei/static@demo/img/test.jpg"
	// 1.图片路径转成canvas
	const tempCanvas = await imgToCanvas(imgUrl)
	// 2.canvas添加水印
	const canvas = addWatermark(tempCanvas, text)
	// 3.canvas转成img
	const img = convasToImg(canvas)
	cb(img)
}

export default createWater