<template>
	<div>
		<el-dialog v-model="showVipModal" append-to-body :close-on-click-modal="false" :before-close="close" width="720px">
			<template v-slot:title>
				<div class="flex_c">
					<p class="fs_18 bold">开通企业会员</p>
				</div>
			</template>
			<div class="content" v-if="!showPaycode">
				<div class="count bottom_link flex_c">
					<div class="vip-tag flex_cen fs_16 bold">
						<img src="@/assets/imgs/icon/vip-icon.png" class="icon" alt="" />
						{{ companyVips[pack]?.name ?? "年费企业会员" }}
					</div>
					<el-radio-group v-model="pack" size="large" fill="#ff7300">
						<el-radio-button v-for="(item, index) in companyVips" :key="index" :label="index">
							￥{{ item.amount }}
						</el-radio-button>
					</el-radio-group>
					<!-- <div class="fs_20 vip-price" fill="#fff">
            ￥{{ item.amount ?? 0 }}元
          </div> -->
				</div>
				<div class="mt_15">
					<p class="fs_14">购买后您将会获得以下权限</p>
					<div class="flex_c mt_15">
						<div class="interests-item mr_10 center flex_col in">
							<p class="fs_14 color_666">会员资格</p>
							<p class="fs_28 color">{{ companyVips[pack]?.days ?? 0 }}天</p>
							<p class="fs_12 color_666">有效期内可正常使用所有功能</p>
						</div>
						<div class="interests-item mr_10 center flex_col in">
							<p class="fs_14 color_666">赠送CRC</p>
							<p class="fs_28 color">{{ companyVips[pack]?.num ?? 0 }}</p>
							<p class="fs_12 color_666">可用于查看人才及及其他更多用途</p>
						</div>
						<div class="interests-item mr_10 center flex_col in">
							<p class="fs_14 color_666">专属客服</p>
							<p class="fs_28 color">1v1</p>
							<p class="fs_12 color_666">享受平台指定专属1v1客服服务</p>
						</div>
					</div>
				</div>
				<div class="mt_20">
					<p class="fs_14">支付方式</p>
					<div class="flex_c mt_15 pay-type">
						<div class="flex_cen item">
							<img src="@/assets/imgs/icon/wechat-pay.png" class="pay-icon" alt="微信支付" />
							<p class="fs_14 color_000">微信支付</p>
						</div>
					</div>
				</div>
			</div>

			<!-- 付款码 -->
			<div class="content center pay-code" v-else>
				<div class="qrbox flex_cen">
					<div ref="qrCode"></div>
				</div>
				<p class="color_999 fs_12">请使用微信扫一扫完成支付</p>
				<p class="fs_20 vip-price mt_10">￥{{ companyVips[pack]?.amount ?? 0 }}元</p>
			</div>

			<template v-slot:footer>
				<div class="flex_cen">
					<el-button type="primary" class="pay-btn" size="medium" :loading="loading" v-if="!showPaycode"
						@click="createOrder">去支付</el-button>
					<el-button type="primary" class="pay-btn" size="medium" v-else @click="checkedPay">已完成支付
						<!-- {{ hous }}:{{ min }}:{{ sec }} --></el-button>
				</div>
			</template>
		</el-dialog>
		<invoice-dialog @cb="callback" :orderId="orderId" :shipAddress="true" :buttonText="buttonText"
			ref="invoiceDialog"></invoice-dialog>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import invoiceDialog from "./invoice-dialog"
import QRCode from "qrcodejs2"
export default {
	name: "payVipDialog",
	components: { invoiceDialog },
	data() {
		return {
			vipData: {},
			showPaycode: false,
			loading: false,

			hous: "00", // 时
			min: "00", // 分
			sec: "00", // 秒,

			orderId: "",
			buttonText: "确认开票",
			pack: 0
		}
	},
	computed: {
		...mapGetters({
			showVipModal: "showVipModal",
			companyVips: "getCompanyVips"
		})
	},
	methods: {
		...mapActions({
			setCompanyInfo: "SETCOMPANY"
		}),
		...mapMutations({
			showDialog: "set_showVipModal",
			get_userInfo: "get_userInfo"
		}),
		close() {
			this.showPaycode = false
			this.showDialog(false)
		},
		callback() {
			this.$emit("cb")
		},
		checkedPay() {
			this.showPaycode = false
			this.showDialog(false)
			this.get_userInfo()
			this.callback()
			this.$axios({
				url: `/ent/v3/usercenter/company/order/${this.orderId}`,
				method: "GET",
				hideLoading: true
			}).then((res) => {
				if (res.code === 200 && res.data.payment_status) {
					this.$refs.invoiceDialog.dialogVisible = true
					this.$refs.invoiceDialog.getInvoice()
				}
			})
		},
		createOrder() {
			this.loading = true
			this.$axios({
				url: "/ent/v3/usercenter/company/order/create",
				method: "POST",
				data: {
					pack: this.companyVips[this.pack].pack
				},
				hideLoading: true
			}).then((res) => {
				if (res.code === 200) {
					const { order_id, code_url } = res.data
					this.showPaycode = true
					this.orderId = order_id
					this.$nextTick(() => {
						this.creatQrCode(code_url)
					})
					// this.countTime()
				}
				this.loading = false
			})
		},
		// 支付二维码
		creatQrCode(qrLink) {
			new QRCode(this.$refs.qrCode, {
				text: qrLink, // 需要转换为二维码的内容
				width: 200,
				height: 200,
				colorDark: "#000000",
				colorLight: "#ffffff",
				correctLevel: QRCode.CorrectLevel.H
			})
		},
		checkTime(i) {
			return i < 10 ? "0" + i : i
		},
		countTime() {
			var date = new Date()
			var now = date.getTime()
			//截止时间
			var str = "2022/02/15 20:43:00"
			// var str = this.endtime
			var endDate = new Date(str)
			var end = endDate.getTime()
			var leftTime = end - now
			var h, m, s
			if (leftTime >= 0) {
				h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
				m = Math.floor(leftTime / 1000 / 60 % 60)
				s = Math.floor(leftTime / 1000 % 60)
				//格式化时间
				this.hous = this.checkTime(h)
				this.min = this.checkTime(m)
				this.sec = this.checkTime(s)
				this.timer = setTimeout(() => {
					this.countTime()
				}, 1000)

				if (h <= 0 && m <= 0 && s <= 0) {
					window.clearTimeout(this.timer)
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.pay-btn {
	width: 200px;
	height: 52px;
	font-size: 18px;
}

.content {
	padding: 0px 20px 40px;

	&.pay-code {
		padding-top: 60px;
	}

	.count {
		padding-bottom: 16px;
	}

	.vip-tag {
		width: 140px;
		height: 40px;
		background: linear-gradient(to right, #747474, #1a1a1a);
		color: #ead7b2;
		border-radius: 4px;
		margin-right: 15px;

		.icon {
			width: 22px;
			height: auto;
			margin-right: 3px;
		}
	}

	.vip-price {
		color: #ff7300;
		font-family: "Segoe UI";
	}

	.interests-item {
		padding: 15px 20px;
		border: 1px solid #ddd;
		width: 140px;
		height: 140px;
		border-radius: 5px;

		&.in {
			border: 2px solid #1890ff;
		}
	}

	.pay-type {
		.item {
			width: 140px;
			height: 40px;
			border-radius: 5px;
			border: 2px solid #1890ff;

			.pay-icon {
				width: 20px;
				height: auto;
				margin-right: 5px;
			}
		}
	}

	.qrbox {
		border: 1px solid #ccc;
		width: 220px;
		height: 220px;
		margin: 0 auto 5px;
	}
}
</style>
