<template>
	<el-collapse-transition>
		<div class="list" v-if="showTabel" @mouseleave="showTabel = false" @click.stop.prevent="">
			<div class="center fs_16 bold color_333 padding_20 bottom_link">
				面试安排
			</div>
			<div class="flex">
				<div class="left" :class="{ auto: list.length > 7 }">
					<p class="_item btn" :class="{ in: tab == item.date }" v-for="(item, index) in list" :key="index" @click.stop.prevent="changeTab(item.date)">
                        <el-badge :value="item.num" :max="10" type="danger" :hidden="item.num<=0">
                            {{ item.label }}
                        </el-badge>
					</p>
				</div>
				<div>
					<div class="right flex_cen" v-if="interviewList.length == 0">
						<div class="info_color fs_14 center">暂无面试安排</div>
					</div>
					<div class="right" v-else>
						<div v-for="(item, index) in interviewList" :key="index">
							<p class="fs_14 sub_color padding_10">{{ item.value }}</p>
							<p class="flex fs_14 color_666 padding_10" v-for="(val, i) in item.list" :key="i" @click="handleDialog(val.user_id)">
								<span class="w_60">{{ val.hour }}</span>
								<span class="w_95 center">{{ val.realname }}</span>
								<span class="flex_1 center">{{ val.job_name }}</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</el-collapse-transition>
	<resumeInfo ref="resumeInfo"></resumeInfo>
</template>

<script>
import resumeInfo from "../views/talents-hall/dialog/resume-info.vue"
export default {
	name: "Invited-tabel",
	components: { resumeInfo },
	data() {
		return {
			tab: "",
			list: [],
			interviewList: [],
			showTabel: false,
			forenoon: {}
		}
	},
	methods: {
		init() {
			this.$axios({
				url: "/ent/v3/index/plan",
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = res.data.day_list
					this.tab = this.list[0] ? this.list[0].date : ""
					if (this.tab != "") this.getList()
				}
			})
		},
		getList() {
			this.$axios({
				url: "/ent/v3/index/plan",
				method: "POST",
				data: {
					date: this.tab
				},
				hideLoading: true,
				shake: false
			}).then(res => {
				if (res.code === 200) {
					let list = []
					let num = 0
					for (const key in res.data.list) {
						list[num] = {
							value: key,
							list: res.data.list[key]
						}
						num++
					}
					this.interviewList = list
				}
			})
		},
		changeTab(date) {
			if (this.tab == date) return
			this.tab = date
			this.getList()
		},
		handleDialog(id) {
			this.$axios({
				url: `/ent/v3/talent/view_user/${id}`,
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.$refs.resumeInfo.data = res.data
					this.$refs.resumeInfo.dialogVisible = true
				}
			})

		}
	}
}
</script>

<style lang="less" scoped>
/deep/.el-badge__content {
    height: 16px;
    line-height: 16px;
    padding: 0 4px;
}
.w_60 {
	width: 60px;
}
.w_95 {
	width: 95px;
}
.list {
	position: absolute;
	width: 537px;
	height: 410px;
	top: 28px;
	right: -20px;
	z-index: 9;
	background-color: #fff;
	box-shadow: 0px 3px 6px rgba(149, 149, 149, 0.16);
	.left {
		width: 180px;
		height: 349px;
		background: #f8f8f8;
		&.auto {
			overflow-y: scroll;
		}
		._item {
			font-size: 14px;
			color: #999;
			padding: 14px 0 12px 20px;
            line-height: 22px;
            transition: color .2s ease;
			&:hover {
                color: #1890ff !important;
                transition: color .2s ease;
			}
		}
		.in {
			background-color: #fff;
			color: #333 !important;
		}
	}
	.right {
		height: 349px;
		width: 337px;
		overflow-y: scroll;
		// padding: 0 20px;
		padding-left: 20px;
		background-color: #fff;
		p {
			line-height: 22px;
		}
	}
}
</style>
