<template>
    <el-dialog v-model="dialogVisible" :lock-scroll="true" append-to-body width="840px" top="13vh" destroy-on-close>
        <template v-slot:title>
            <div class="rule-tab">
                <p class="fs_18 bold item btn">开票信息</p>
            </div>
        </template>
        <div class="content">
            <el-form :model="ruleForms" :rules="rules" ref="ruleForms" label-position="top" class="_form">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="企业发票抬头" prop="company_name">
                            <el-input type="text" placeholder="请填写企业发票抬头" readonly v-model="ruleForms.company_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="税务登记号" prop="creditcode">
                            <el-input type="text" placeholder="请填写税务登记号" readonly v-model="ruleForms.creditcode"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="发票类型" prop="invoice_type">
                            <el-select v-model="ruleForms.invoice_type" placeholder="请选择发票类型" style="width:100%" :disabled="readonly" @change="changeInvoice">
                                <!-- <el-option label="请选择发票类型" value="-1"></el-option> -->
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="基本开户账号" prop="bank_account">
                            <el-input type="number" :placeholder="readonly?'/':'请填写基本开户账号'" :readonly="readonly" v-model.trim="ruleForms.bank_account"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开户银行名称" prop="bank_name">
                            <el-input type="text" :placeholder="readonly?'/':'请填写开户银行名称'" :readonly="readonly" v-model.trim="ruleForms.bank_name" @input="ruleForms.bank_name=ruleForms.bank_name.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="注册固定电话" prop="phone">
                            <el-input type="number" :placeholder="readonly?'/':'请填写注册固定电话'" :readonly="readonly" v-model.trim="ruleForms.phone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="注册场所地址" prop="address">
                            <el-input type="text" :placeholder="readonly?'/':'请填写注册场所地址'" :readonly="readonly" v-model.trim="ruleForms.address" @input="ruleForms.address=ruleForms.address.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="mt_20" v-if="shipAddress">
                    <div class="fs_16 bold color_333 title mb_15">收件信息</div>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="收件人" :rules="rules.ship_name">
                                <el-input type="text" placeholder="请填写收件人" :readonly="readonly" v-model.trim="ruleForms.ship_name" @input="ruleForms.ship_name=ruleForms.ship_name.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="收件人电话" :rules="rules.ship_phone">
                                <el-input type="number" placeholder="请填写收件人电话" :readonly="readonly" v-model.trim="ruleForms.ship_phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="收件地址" :rules="rules.ship_address">
                                <el-input type="text" placeholder="请填写收件地址" :readonly="readonly" v-model.trim="ruleForms.ship_address" @input="ruleForms.ship_address=ruleForms.ship_address.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                </div>
            </el-form>
        </div>
        <template v-slot:footer>
            <div class="flex_cen">
                <el-button type="default" class="pay-msg-button" @click="dialogVisible=false" v-if="!readonly">
                    以后开票
                </el-button>
                <el-button type="primary" class="pay-msg-button" :loading="loading" @click="submitForm('ruleForms')">
                    {{ buttonText }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
var checkMobile = (rule, value, callback) => {
	if (value === '') {
		callback(new Error('请填写收件人电话'))
	} else if (!isPhone(value)) {
		callback(new Error('请填写正确的收件人电话'))
	} else {
		callback()
	}
}
export default {
	data() {
		return {
			readonly: true,
			dialogVisible: false,
			loading: false,
			ruleForms: {
				company_name: "",
				creditcode: "",
				invoice_type: "",
				bank_name: "",
				bank_account: "",
				address: "",
				phone: "",
				ship_name: "",
				ship_address: "",
				ship_phone: ""
			},
			options: [ {
				value: 0,
				label: '增值税普通发票'
			}, {
				value: 1,
				label: '增值税专用发票'
			} ],
			rules: {
				company_name: [
					{ required: true, message: '请填写企业发票抬头', trigger: 'change' }
				],
				creditcode: [
					{ required: true, message: '请填写税务登记号', trigger: 'change' }
				],
				invoice_type: [
					{ required: true, message: '请选择发票类型', trigger: 'change' }
				],
				bank_name: [/* 动态验证 */],
				bank_account: [/* 动态验证 */],
				address: [/* 动态验证 */],
				phone: [/* 动态验证 */],
				ship_name: [
					{ required: true, message: '请填写收件人', trigger: 'change' }
				],
				ship_address: [
					{ required: true, message: '请填写收件地址', trigger: 'change' }
				],
				ship_phone: [
					{ required: true, validator: checkMobile, trigger: 'blur' }
				]
			}
		}
	},
	props: {
		buttonText: {
			default: '确认修改',
			type: String
		},
		shipAddress: {
			default: false,
			type: Boolean
		},
		orderId: {
			default: '',
			type: String || Number
		}
	},
	methods: {
		getInvoice() {
			this.readonly = false
			this.$axios({
				url: '/ent/v3/usercenter/company/invoice',
				method: 'GET',
				hideLoading: true
			}).then(res=>{
				if(res.code === 200) {
					this.ruleForms = res.data.invoice
					if(this.ruleForms.invoice_type===false) {
						this.ruleForms.invoice_type = ''
					}
					this.setRules(this.ruleForms.invoice_type)
				}
			})
		},
		changeInvoice(e) {
			this.setRules(e)
			this.$refs.ruleForms.clearValidate()
		},
		// 必填项切换
		setRules(e) {
			if(e === 1) {
				this.rules.bank_account.push({ required: true, message: '请填写基本开户账号', trigger: 'change' })
				this.rules.bank_name.push({ required: true, message: '请填写开户银行名称', trigger: 'change' })
				this.rules.address.push({ required: true, message: '请填写注册场所地址', trigger: 'change' })
				this.rules.phone.push({ required: true, message: '请填写注册固定电话', trigger: 'change' })
			}else {
				this.rules.bank_account = []
				this.rules.bank_name = []
				this.rules.address = []
				this.rules.phone = []
			}
		},
		submitForm() {
			if(this.readonly) {
				this.dialogVisible = false
				return
			}
			this.$refs.ruleForms.validate((valid) => {
				if (valid) {
					// if(this.ruleForms.invoice_type == -1) {
					// 	this.ruleForms.invoice_type = ''
					// 	return
					// }
					this.loading = true
					let url = this.orderId ? `/ent/v3/usercenter/company/order/invoice/${this.orderId}` : '/ent/v3/usercenter/company/invoice'

					this.$axios({
						url,
						method: 'POST',
						data: this.ruleForms,
						shake: true,
						hideLoading: true
					}).then(res => {
						setTimeout(() => {
							this.loading = false
						}, 900)
						if (res.code === 200) {
							this.$message.success("保存成功")
							this.dialogVisible = false
							this.$emit('cb', true)
						}
					})
				}
			})
		}
	}
}
</script>

<style lang='less' scoped>
.content {
    padding: 0 30px;
}
/deep/._form {
    .el-form-item__label {
        padding: 0 !important;
    }
    .el-input__inner {
        height: 44px;
        background-color: #f9f9f9;
    }
    .el-form-item__label {
        font-size: 16px;
    }
    .title {
        padding-bottom: 20px;
        border-bottom: 1px dashed #ededed;
    }
}

.el-button {
    width: 200px;
    height: 52px;
    // margin-top: 60px;
    font-size: 18px;
}
</style>