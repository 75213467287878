import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.js'
import elements from './common/element'
import axios from './request/axios'
import searchBar from './components/layout/search-bar.vue'
import empty from './components/empty.vue'
import payVipDialog from '@/components/payvip-dialog'
import './common/aliyun-upload-sdk.js'
import {
	l_getItem
} from "@/common/cache"
import {
	ElMessageBox
} from 'element-plus'

import invitedTabel from "@/components/invited-tabel"


const app = createApp(App)

import lang from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale'

// 设置语言
locale.use(lang)

app.component('searchBar', searchBar)
app.component('empty', empty)
app.component('invitedTabel', invitedTabel)
app.component('payVipDialog', payVipDialog)

app.config.globalProperties.$axios = axios
app.config.globalProperties.rowStyle = {
	height: '52px'
}


app.config.globalProperties.$ELEMENT = {
	size: 'small',
	zIndex: 3000
}
elements.forEach(item => {
	if (item.name == "ElInput") {
		item.props.autocomplete.default = 'on'
	}
	app.use(item)
})

app.config.globalProperties.authPath = function (params, callback) {
	let userInfo = l_getItem("USERINFO") || ''
	let companyInfo = l_getItem("COMPANY") || ''

	if (!userInfo) return
	if (userInfo.company_status === 'no_apply') {
		ElMessageBox.confirm("此操作需要先认证企业，是否继续?", "提示", {
			confirmButtonText: "确 定",
			cancelButtonText: "取 消",
			type: "warning"
		}).then(() => {
			router.push('/create')
		})
	} else if (userInfo.company_status === 'apply') {
		ElMessageBox.confirm("企业信息审核中，查看审核进度?", "提示", {
			confirmButtonText: "确 定",
			cancelButtonText: "取 消",
			type: "warning"
		}).then(() => {
			router.push('/inReview')
		})
	} else if (!companyInfo.company_lv) {
		// 显示vip弹窗
		ElMessageBox.confirm("此功能为付费会员功能，开通会员后即可正常使用！", "提示", {
			confirmButtonText: "开通会员",
			cancelButtonText: "暂不开通",
			type: "warning"
		}).then(() => {
			store.commit('set_showVipModal', true)
		})
	} else {
		callback && callback(...params)
	}
}

app.config.globalProperties.arrFilter = function (arr) {
	for (var i = 0; i < arr.length; i++) {
		for (var j = i + 1; j < arr.length; j++) {
			if (arr[i].id == arr[j].id) { //第一个等同于第二个，splice方法删除第二个
				arr.splice(j, 1)
				j--
			}
		}
	}
	return arr
}

app.config.globalProperties.textFilter = function (text) {
	return text.replace(/[^\u4E00-\u9FA5A-Za-z]/g, '')
}

app.use(store).use(router).mount('#app')