<template>
	<div class="ruleDialog">
		<el-dialog
			v-model="dialogVisible"
			:lock-scroll="true"
			width="460px"
			top="20vh"
		>
			<template v-slot:title>
				<div class="flex_c rule-tab">
					<p
						class="fs_16 bold item btn"
						:class="{ in: tab == 1 }"
						@click="tab = 1"
					>
						企业CRC规则
					</p>
					<p
						class="fs_16 bold item btn"
						:class="{ in: tab == 2 }"
						@click="tab = 2"
					>
						部门CRC规则
					</p>
				</div>
			</template>
			<div class="content" v-if="tab == 1">
				<p class="fs_16 bold color_000">CRC获取规则</p>
				<div class="mt_20 fs_14 color_333">
					<p>1、企业帐户注册审核生效：+{{ configure?.company_reg }}CRC</p>
					<p class="mt_5">
						2、开立一个正式员工离职证明申请：+{{ configure?.company_staff_leave_1 }}CRC
					</p>
					<p class="mt_5">
						3、开立一个外部人员工作记录：+{{ configure?.company_staff_leave_2 }}CRC
					</p>
				</div>
				<p class="padding_20"></p>
				<p class="fs_16 bold color_000">CRC使用规则</p>
				<div class="mt_20 fs_14 color_333">
					<p>1、查看待业人才信息：-{{ configure?.check_info }}CRC</p>
					<p class="mt_5">2、操作入职人才保护：-{{ configure?.job_switch }}CRC</p>
					<p class="mt_5">3、企业紧急用工支援抵扣每人100元；-100.00CRC</p>
					<p class="mt_5">4、平台内商家线下提供服务5%~20%抵扣</p>
				</div>
			</div>
			<div class="content" v-if="tab == 2">
				<p class="fs_16 bold color_000">CRC获取规则</p>
				<div class="mt_20 fs_14 color_333">
					<p>1、开立正式员工的离职证明申请：+{{ configure?.op_leave }}CRC</p>
					<p class="mt_5">
						2、开立外部人员的工作记录：+{{ configure?.op_leave }}CRC
					</p>
				</div>
				<p class="padding_20"></p>
				<p class="fs_16 bold color_000">CRC使用规则</p>
				<div class="mt_20 fs_14 color_333">
					<p>1、商城礼品兑换照价扣除</p>
					<p class="mt_5">2、平台部门服务照价抵扣</p>
				</div>
			</div>
			<template v-slot:footer>
				<div class="flex_around">
					<el-button
						type="primary"
						size="medium"
						@click="dialogVisible = !dialogVisible"
						>知道了</el-button
					>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
	name: "",
	data() {
		return {
			dialogVisible: false,
			tab: 1,
			data: {}
		}
	},
	computed: {
		...mapGetters({
			configure: "getConfigure"
		})
	}
}
</script>

<style lang="less" scoped>
.ruleDialog {
	/deep/.el-dialog__header {
		padding: 0;
	}
	/deep/.el-dialog__body {
		padding: 0;
	}
	.rule-tab {
		padding: 0 20px;
		.item {
			margin-right: 20px;
			line-height: 54px;
			border-bottom: 2px solid #fff;
			&.in {
				color: #1890ff;
				border-color: #1890ff;
			}
		}
	}
	.content {
		border-top: 1px solid #ddd;
		padding: 40px 55px;
	}
}
</style>
