<template>
	<el-dialog v-model="dialogVisible" :lock-scroll="true" title="" width="30vw" top="10vh" :show-close="false">
		<template v-slot:title>
			<div class="flex_c">
			</div>
		</template>
		<div class="content">
			<img :src="lzzm" class="leve-title" alt="证明文件">
		</div>
		<div class="utils flex_col">
			<el-button type="primary" size="medium" @click="dialogVisible=false"><i class="el-icon-close fs_20"></i></el-button>
		</div>
	</el-dialog>
</template>

<script>
// 离职证明
import createWater from "@/common/watermark"
import { mapGetters } from "vuex"
export default {
	data() {
		return {
			dialogVisible: false,
			pic: '', // 离职证明申请
			lzzm: '' // 打水印的离职证明申请
		}
	},
	computed: {
		...mapGetters({
			companyInfo: 'getCompanyInfo'
		})
	},
	methods: {
		init() {
			// 打水印
			createWater(this.pic, { company: `${this.companyInfo.company_name}`, info: `${this.companyInfo.name} ${this.getDate()}` }, img=>{
				this.lzzm = img
			})
		},
		authBook() {
			this.$parent.$refs.authBook.dialogVisible = true
		},
		print() {

		},
		getDate() {
			let date = new Date()
			let year = date.getFullYear()
			let month = date.getMonth() + 1
			month = month >= 10 ? month : '0' + month
			let day = date.getDate()
			day = day >= 10 ? day : '0' + day
			let hours = date.getHours()
			let minu = date.getMinutes()
			return `${year}-${month}-${day} ${hours}:${minu}`
		}
	}
}
</script>

<style lang='less' scoped>
.content {
	background: #d4d4d4;
	position: relative;
	margin-top: -40px;
	/deep/.el-dialog__body {
		padding: 0;
	}
}
.utils {
	position: absolute;
	right: -60px;
	top: 0;
	z-index: 99;
	.el-button {
		width: 50px;
		height: 50px;
		margin: 0 0 10px;
		font-size: 14px;
		background: #fff;
		color: #333;
		border-color: #fff;
        padding: 0;
        transition: all .2s ease;
		&:hover {
			background: #1890ff;
			color: #fff;
            border-color: #1890ff;
            transition: all .2s ease;
		}
	}
}
</style>